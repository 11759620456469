<template>
  <v-container
    style="max-width: 1200px"
    class="justify-center d-flex flex-column fill-width"
    v-if="currentUser"
    >
    <Header
      title="Analíticas"
      ></Header>

    <v-card>
      <v-card-title
        class="justify-center"
        >
        Analíticas globales
      </v-card-title>

      <v-card-text
        v-if="store"
        >
        <v-row
          align="center"
          >
          <v-col
            cols="12"
            >
            <v-card
              outlined
              >
              <v-card-title
                class="justify-center"
                >
                Histograma de órdenes
              </v-card-title>
              <v-card-text>
                <v-sparkline
                  v-if="aPrices.length"
                  :smooth="5"
                  color="secondary"
                  stroke-linecap="round"
                  :labels="labels"
                  :value="aPrices"
                  line-width="2"
                  padding="24"
                  ></v-sparkline>

                <v-alert
                  v-else
                  outlined
                  color="info"
                  >
                  Aún no hay suficiente información para mostrar la gráfica.
                </v-alert>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
            >
            <v-card
              outlined
              >
              <v-card-title
                class="justify-center text-center"
                >
                Retención de usuarios
              </v-card-title>
              <v-card-text
                class="text-center"
                >
                <div
                  class="title font-weight-regular black--text"
                  >
                  {{ store.averageTime }} <span class="caption">segundos</span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
            >
            <v-card
              outlined
              >
              <v-card-title
                class="justify-center text-center"
                >
                Visitas a la tienda
              </v-card-title>
              <v-card-text
                class="text-center"
                >
                <div
                  class="title font-weight-regular black--text"
                  >
                  {{ store.storeTracker.views }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
            >
            <v-card
              outlined
              >
              <v-card-title
                class="justify-center text-center"
                >
                Total de órdenes
              </v-card-title>
              <v-card-text
                class="text-center"
                >
                <div
                  class="title font-weight-regular black--text"
                  >
                  {{ store.storeTracker.orders }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
            >
            <v-card
              outlined
              >
              <v-card-title
                class="justify-center text-center"
                >
                Órdenes concretadas
              </v-card-title>
              <v-card-text
                class="text-center"
                >
                <div
                  class="title font-weight-regular black--text"
                  >
                  {{ store.storeTracker.completedOrders }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
            >
            <v-card
              outlined
              >
              <v-card-title
                class="justify-center text-center"
                >
                Órdenes pendientes
              </v-card-title>
              <v-card-text
                class="text-center"
                >
                <div
                  class="title font-weight-regular black--text"
                  >
                  {{ store.storeTracker.pendingOrders }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
            >
            <v-card
              outlined
              >
              <v-card-title
                class="justify-center text-center"
                >
                Producto más visitado
              </v-card-title>
              <v-card-text
                class="text-center"
                >
                <div
                  class="body-1 font-weight-regular black--text"
                  >
                  {{ (store.storeTracker.mostSeenProduct || {}).name || '-' }}
                </div>
                <div
                  class="title font-weight-regular black--text caption"
                  >
                  {{ (store.storeTracker.mostSeenProduct || {}).sku }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
            >
            <v-card
              outlined
              >
              <v-card-title
                class="justify-center text-center"
                >
                Producto más comprado
              </v-card-title>
              <v-card-text
                class="text-center"
                >
                <div
                  class="body-1 font-weight-regular black--text"
                  >
                  {{ (store.storeTracker.mostBoughtProduct || {}).name || '-' }}
                </div>
                <div
                  class="title font-weight-regular black--text caption"
                  >
                  {{ (store.storeTracker.mostBoughtProduct || {}).sku }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row
          align="center"
          >
          <v-col
            cols="6"
            sm="4"
            md="3"
            >
            <v-card
              outlined
              >
              <v-card-title
                class="justify-center text-center red--text"
                >
                Órdenes abandonadas
              </v-card-title>
              <v-card-text
                class="text-center"
                >
                <div
                  class="title font-weight-regular black--text"
                  >
                  {{ store.storeTracker.abandonedOrders }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
            >
            <v-card
              outlined
              >
              <v-card-title
                class="justify-center text-center red--text"
                >
                Punto conflictivo
              </v-card-title>
              <v-card-text
                class="text-center"
                >
                <div
                  class="title font-weight-regular black--text"
                  >
                  {{ store.storeTracker.dropoutState ? $t('states.' + store.storeTracker.dropoutState) : '-' }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { Analytics } from '@/graphql/queries/stores'

import Header from '@/components/admin/shared/Header'

export default {
  data () {
    return {
      store: null
    }
  },

  components: {
    Header,
  },

  created () {
    this.fetchStore ()
  },

  computed: {
    ...mapGetters(['currentUser']),

    orders () {
      if (this.store) {
        var orders = this.store.orders.sort((a,b) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0))
        return orders.reduce((rv, x) => {
          (rv[x['createdAt']] = rv[x['createdAt']] || []).push(x);
          return rv;
        }, {});
      }
    },

    labels () {
      return Object.keys(this.orders).reverse()
    },

    aPrices () {
      if (this.orders) {
        return Object.values(this.orders).map( el => el.length ).reverse()
      }
    }
  },

  methods: {
    fetchStore () {
      this.$apollo.query({
        query: Analytics
      }).then ( res => {
        this.store = res.data.store
      })
    }
  }
}
</script>
